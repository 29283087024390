@font-face {
    font-family: 'publicsans';
    src: url('publicsans-extrabold.woff2') format('woff2'),
         url('publicsans-extrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'publicsans';
    src: url('publicsans-extrabolditalic.woff2') format('woff2'),
         url('publicsans-extrabolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'publicsans';
    src: url('publicsans-lightitalic.woff2') format('woff2'),
         url('publicsans-lightitalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'publicsans';
    src: url('publicsans-light.woff2') format('woff2'),
         url('publicsans-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
:root {
  --content-width: 38rem;
  --content-padding: 3rem;
  --site-border-width: 6px;
  --color-mode: 'light';
  --color-dark: #1E1E1F;
  --color-darkish: #535354;
  --color-white: #FFF;
  --color-light: #E1E4E5;
  --color-lightish: #e1e3e4;
  --text-color: var(--color-darkish);
  --heading-color: var(--color-dark);
  --primary-color: #5FB49C;
  --bg-color: var(--color-white);
  --secondary-bg-color: var(--color-lightish);
}

$breakpoint-sm : 435px;
$breakpoint-md : 600px;
$breakpoint-lg : 800px;
$breakpoint-xl : 1000px;

@media (prefers-color-scheme: dark) {
  :root {
    --color-mode: 'dark';
  }
  :root:not([data-user-color-scheme]) {
    --text-color: var(--color-lightish);
    --heading-color: var(--color-light);
    --bg-color: var(--color-dark);
    --secondary-bg-color: var(--color-darkish);
  }
}
[data-user-color-scheme='dark'] {
  --text-color: var(--color-lightish);
  --heading-color: var(--color-light);
  --bg-color: var(--color-dark);
  --secondary-bg-color: var(--color-darkish);
}
/* For Screen readers */
.visually-hidden {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'publicsans', sans-serif;
  font-size: 100%;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
}
.site-wrapper::before {
  content: " ";
  border-top: var(--site-border-width) solid var(--primary-color);
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}
* {
  box-sizing: border-box;
}
@media screen and (min-width: $breakpoint-md) {
  body {
    font-size: 120%;
  }
}
main > *,
aside > * {
  margin-bottom: calc(var(--content-padding) / 2);
  max-width: var(--content-width);
}
.alignwide {
  padding: calc(var(--content-padding) * 2) calc(var(--content-padding) - var(--site-border-width));
  margin-left: calc(var(--content-padding) * -1);
  max-width: 100vw;
  width: 100vw;
}
.alignwide > * {
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
  font-weight: 700;
  line-height: 1.4;
  margin-top: var(--content-padding);
  margin-bottom: calc(var(--content-padding) / 2);
}
h1 {
  line-height: 1.2;
}
a {
  color: var(--heading-color);
  border-bottom: 2px solid var(--primary-color);
  text-decoration: none;
  transition: 0.2s ease;
}
a:hover,
a:focus {
  background-color: var(--primary-color);
  color: var(--bg-color);
}
strong {
  color: var(--heading-color);
  font-weight: bold;
}
em {
  font-style: italic;
}
p, ul, ol, dl {
  margin-bottom: calc(var(--content-padding) / 2 );
  margin-top: 0;
}
small {
  opacity: 0.6;
}
blockquote {
  border-top: var(--site-border-width) solid var(--primary-color);
  margin-left: 0;
  margin-right: 0;
  margin-top: var(--content-padding);
  margin-bottom: var(--content-padding);
  padding: calc(var(--content-padding) / 2) 0;

  > p {
    font-size: 1.3em;
    font-weight: bold;
    line-height: 1.2;
  }

  .author {
    border-top: var(--site-border-width) solid var(--primary-color);
    display: grid;
    grid-template-columns: 4rem auto;
    grid-gap: 1em;
    padding-top: calc(var(--content-padding) / 2);

    p {
      display: flex;
      flex-direction: column;
      height: 4rem;
      justify-content: center;
      line-height: 1.2;
      margin-bottom: 0;

      span {
        display: block;
        font-size: 0.9rem;

        &.author-name {
          font-weight: bold;
          letter-spacing: 0.1em;
          margin-bottom: 0.25em;
          text-transform: uppercase;
        }
      }
    }

    .author-avatar {
      height: 100%;
      margin: 0;


      img {
          border-radius: 2rem;
      }
    }
  }

}
figure {
  margin: 0 0 var(--content-padding);
}
figure figcaption {
  opacity: 0.7;
}
code {
	background-color: var(--secondary-bg-color);
	padding: 0.25em 0.5em;
}
.site-header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: calc(var(--content-padding) / 2) var(--content-padding);
  padding-top: var(--content-padding);
  padding-bottom: var(--content-padding);

  .profile-pic {
    grid-column: 1 / 2;
    margin-bottom: 0;

    a {
      border: none;

      img {
        display: block;
        transition: transform 0.4s ease;
      }

      &:hover,
      &:focus {
        background: none;

        img {
          transform: rotate(360deg);
        }
      }
    }

    img {
      height: auto;
      max-width: 175px;
    }
  }

  .header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 1 / 3;

    .site-title {
      font-size: 1.75rem;
      font-weight: bold;

      a {
        border-bottom: none;

        &:hover,
        &:focus {
          color: var(--bg-color);
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-xl) {

    grid-template-columns: 1fr var(--content-width) 1fr;
    grid-gap: 0;

    .profile-pic {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-right: var(--content-padding);
    }

    .header-title {
      grid-column: 2 / 3;
      margin-right: var(--content-padding);
    }

  }
}

.breadcrumb {
  margin-bottom: calc(var(--content-padding));
}
.site-nav {
  display: flex;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  flex-direction: column;
  align-items: flex-end;


  @media screen and (min-width: $breakpoint-lg) {
    grid-column: 3 / 4;
  }

  > ul  {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    text-align: right;
    transition: opacity 0.1s ease,
                transform 0.25s ease;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    visibility: hidden;

  }

  li {
    a {
      border-bottom: none;
      border-radius: 20px;
      display: inline-block;
      padding: 0.25em 1em;
    }

    &.current {

      a:before {
        color: var(--primary-color);
        content: ">";
        font-weight: bold;
        padding-right: 0.25em;
      }

      a:hover:before {
        color: var(--bg-color);
      }
    }
  }

  .site-menu-trigger {
    padding-bottom: 2rem;

    // .menu-trigger {
    //   &::after {
    //     content: ">";
    //     display: inline-block;
    //     padding-left: 0.25em;
    //     transition: transform 0.1s ease;
    //   }
    // }
  }

  &.nav--active {
    ul {
      opacity: 1;
      transform: scaleY(1);
      visibility: visible;
    }


    .site-menu-trigger {
      .menu-trigger {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }


}

.no-js {
  .site-menu-trigger {
    display: none;
  }
  .site-nav {
    > ul {
      opacity: 1;
      transform: scaleY(1);
      visibility: visible;
    }
  }
}

.site-header,
main,
aside,
.site-footer {
  padding-left: calc(var(--content-padding) / 2 );
  padding-right: calc(var(--content-padding) / 2 );

  @media screen and (min-width: $breakpoint-md) {
    padding-left: var(--content-padding);
    padding-right: var(--content-padding);
  }
}
main,
aside {
  margin-bottom: calc(var(--content-padding) * 2);
}
figure img {
  display: block;
  height: auto;
  width: 100%;
}
.site-footer {
  text-align: center;

  h2 {
    font-weight: 300;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  .site-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}
.site-footer h2 {
  font-size: 1em;
}
.social-nav ul {
  list-style: none;
  margin-bottom: var(--content-padding);
  margin-left: 0;
  padding-left: 0;
}
.social-nav ul li {
  display: inline-block;
  margin-right: calc(var(--content-padding) / 2);
}
.social-nav ul li:last-of-type {
  margin-right: 0;
}
.user-toggle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: var(--content-padding);
}
.toggle-button {
  background-color: var(--secondary-bg-color);
  border-color: rgba(255,255,255,0.2);
  border-radius: 25px;
  color: var(--text-color);
  font-size: 1rem;
  font-weight: normal;
  text-transform: capitalize;

  &:hover {
    color: var(--bg-color);
  }
  &:focus {
    color: var(--text-color);
  }
}

/*

Posts

*/

.post-meta {
  opacity: 0.6;
}
.meta__stats {
  font-style: italic;
}

.author-bio {
  background: url(/static/hr.svg) 0 1rem repeat-x;
  background-size: 128px;
  padding-top: 2rem;
}

/*

Blog index

*/
ol.blog-list {
  list-style: none;
  margin-left: auto;
  padding: 0;
  
  > li {
  margin-bottom: var(--content-padding);
  }
}

/*

Projects index

*/
ol.projects-list {
  list-style: none;
  margin-left: auto;
  padding: 0;
  max-width: 120rem;
  
  > li {
  margin-bottom: var(--content-padding);

  figure img {
    object-fit: cover;
    height: 33vh;
    min-height: 200px;
    width: 100%
  }
  }

  @media screen and (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--content-padding);
  }

  @media screen and (min-width: $breakpoint-lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* 

Tags

*/

.project-tags {
  font-size: 0.85em;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
		background-color: var(--primary-color);
		color: var(--color-white);
    display: inline-block;
    margin-bottom: 0.25em;
    padding: 0.1em 0.5em;
  }
}

/*

CTA

*/

.cta-link {
  margin-top: 2em;
  margin-bottom: 2em;
}


/*

Forms and buttons

*/
form {
  display: flex;
  flex-direction: column;
}
label {
  display: inline-block;
  font-weight: bold;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
input:not([type=checkbox]),
select {
  background-color: var(--secondary-bg-color);
  border-radius: 4px;
  border: none;
  color: var(--text-color);
  display: block;
  font-size: 1em;
  margin-bottom: 1.5rem;
  padding: 0.75rem;
}
fieldset {
  border: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0;

  input[type="checkbox"] {
    flex-basis: 5%;
  }
  label {
    flex-basis: 93%;
  }
}
fieldset legend {
  font-weight: bold;
  margin-bottom: 1rem;
}
input[type=submit],
button,
.button {
  background-color: var(--primary-color);
  border: 3px solid var(--primary-color);
  border-radius: 2rem;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.15);
  color: var(--bg-color);
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 0.75rem 1.25rem;
  transition: 0.2s ease;
  
  -webkit-appearance: none;
  -moz-appearance: none;
   appearance: none;

  &:hover {
    border-color: var(--heading-color);
    background-color: var(--heading-color);
  }
  &:focus {
    background-color: var(--bg-color);
    color: var(--text-color);
  }

  &.button2 {
    background-color: var(--bg-color);
    border-color: transparent;
    border-radius: 25px;
    color: var(--text-color);
    padding: 0.75rem 1.25em;

    &:hover {
      background-color: var(--primary-color);
      color: var(--bg-color);
    }
    &:focus {
      background-color: var(--bg-color);
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }
} // buttons

.email-signup {
  display: flex;
  flex-direction: column;
}
.email-signup input[type=email] {
  border-radius: 4px 4px 0px 0px;
  flex-grow: 1;
  margin: 0;
}
.email-signup input[type=submit ] {
  border-radius: 0px 0px 4px 4px;
}
@media screen and (min-width: $breakpoint-sm) {

  .email-signup {
    display: flex;
    flex-direction: row;
  }
  .email-signup input[type=email] {
    border-radius: 4px 0px 0px 4px;
  }
  .email-signup input[type=submit ] {
    border-radius: 0px 4px 4px 0px;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  main > *,
  aside > * {
    margin-left: auto;
    margin-right: auto;
  }
}


/*

Gallery

*/

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  grid-gap: calc(var(--content-padding) / 2);

  figure {
  margin-bottom: 0;

    figcaption {
      font-size: 0.85em;
      font-style: italic;
      text-align: center;
    }
  }
}

.icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-gap: calc(var(--content-padding) * 2);

  figure {

    img {
      margin-bottom: calc(var(--content-padding) / 2);
    }

    figcaption {
      font-size: 0.85em;
      text-align: center;
    }
  }
}

html[data-user-color-scheme='light'] .gallery:not(.borderless) img {
  border: 1px solid var(--color-lightish);
}

.project-header {
  border-bottom: var(--site-border-width) solid var(--primary-color);
  margin-bottom: calc(var(--content-padding) / 2);

  ul {
    letter-spacing: 0.1em;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    text-transform: uppercase;
  }
}

hr {
  border-collapse: collapse;
  border: none;
  border-top: var(--site-border-width) solid var(--primary-color);
  height: 0px;
  margin-top: var(--content-padding);
  margin-bottom: var(--content-padding);
  width: 100%;
}

/*

Projects

*/

.project-grid {
  .full-img {
    figcaption {
      padding-top: calc(var(--content-padding) / 2);
      text-align: center;
    }
  }
}

@media screen and (min-width: $breakpoint-lg) {
  @supports (display: grid) {
    .project-grid {
      display: grid;
      grid-template-columns: repeat(12,1fr);
      grid-gap: calc(var(--content-padding) / 2);
      margin: 0 auto;
      max-width: 1192px;

      > * {
        grid-column: 3 / 11;
      }

      .project-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        grid-column: 9 / 13;
        margin-bottom: var(--site-border-width);
      }

      .fimg {
        grid-column: 1 / 9;
        grid-row: 1 / 2;
        margin-bottom: 0;
      }

      .intro-content {
        margin: calc(var(--content-padding) * 2) 0;
        grid-column: 5 / 13;
      }

      .gallery {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-column: 1 / 13;
        grid-gap: calc(var(--content-padding) / 2);
      }

      blockquote {
        margin: calc(var(--content-padding) * 2) 0;
      }

      .wide {
        grid-column: 1 / 13;
      }

    }
  }
}
